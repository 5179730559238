import type { SystemStyleObject, TooltipProps } from '@chakra-ui/react';
import { Skeleton, Flex, useColorModeValue, chakra, LightMode } from '@chakra-ui/react';
import React from 'react';

import breakpoints from 'theme/foundations/breakpoints';
import Icon from 'ui/shared/chakra/Icon';
import Hint from 'ui/shared/Hint';
import useIsMobile from 'lib/hooks/useIsMobile';

type Props = {
  icon: React.FC<React.SVGAttributes<SVGElement>>;
  title: string;
  value: string;
  className?: string;
  tooltipLabel?: React.ReactNode;
  url?: string;
  isLoading?: boolean;
  leftBorder?: boolean;
  rightBorder?: boolean;
  isTopLine?: boolean;
}

const LARGEST_BREAKPOINT = '1240px';

const TOOLTIP_PROPS: Partial<TooltipProps> = {
  hasArrow: false,
  borderRadius: 'md',
  placement: 'bottom-end',
  offset: [0, 0],
  bgColor: 'blackAlpha.900',
};



const StatsItem = ({ icon, title, value, className, tooltipLabel, url, isLoading, isTopLine, leftBorder, rightBorder }: Props) => {
  const isMobile = useIsMobile();
  const sxContainer: SystemStyleObject = {
    [`@media screen and (min-width: ${breakpoints.lg}) and (max-width: ${LARGEST_BREAKPOINT})`]: { flexDirection: 'column' },
  };

  const sxText: SystemStyleObject = {
    [`@media screen and (min-width: ${breakpoints.lg}) and (max-width: ${LARGEST_BREAKPOINT})`]: { alignItems: 'center' },
  };

  // const bgColor = useColorModeValue('blue.50', 'blue.800');
  // const loadingBgColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.50');
  const infoColor = useColorModeValue('gray.600', 'gray.400');
  const border = "1px solid #e9ecef"

  return (
    <Flex
      // backgroundColor={isLoading ? loadingBgColor : bgColor}
      // padding={3}
      borderRadius=""
      borderLeft={leftBorder ? border : ""}
      borderRight={rightBorder ? border : ""}
      flexDirection="column"
      sx={sxContainer}
      alignItems="center"
      columnGap={0}
      rowGap={2}
      className={className}
      color={useColorModeValue('black', 'white')}
      position="relative"
      {...(url && !isLoading ? {
        as: 'a',
        href: url,
      } : {})}
    >
      {isTopLine && <hr style={{ width: '90%', height: 0.5, backgroundColor: "#e9ecef", borderTopWidth: 0 }} />}
      <Flex
        flex={1}
        width="100%"
        padding={3}
        flexDirection="row"
        sx={sxContainer}
        alignItems="center"
        justifyContent="flex-start"
        columnGap={!isMobile ? 3 : 1}
        rowGap={2}
        className={className}
        color={useColorModeValue('black', 'white')}
        position="relative"
      >
        <Icon as={icon} boxSize={7} isLoading={isLoading} borderRadius="base" />
        <Flex
          flexDirection="column"
          alignItems="start"
          sx={sxText}
        >
          <Skeleton isLoaded={!isLoading} color="text_secondary" fontSize="xs" lineHeight="16px" borderRadius="base" display={'flex'} flexDirection={'column'} width='100%'>
            { title !== "totalmarket" ? 
            <span>{ title }</span> : (!isMobile ? 
            <span>Total Market (CEXs Vol24h)</span> : 
            (<><span style={{textAlign: 'center'}}>Total Market</span><span style={{textAlign: 'center'}}>(CEXs Vol24h)</span></>))
            }
          </Skeleton>
          <Skeleton isLoaded={!isLoading} fontWeight={500} fontSize={!isMobile ? 'md' : 'xs'} color={useColorModeValue('black', 'white')} borderRadius="base">
            <span>{value}</span>
          </Skeleton>
        </Flex>
      </Flex>
      {tooltipLabel && !isLoading && (
        <LightMode>
          <Hint
            label={tooltipLabel}
            tooltipProps={TOOLTIP_PROPS}
            boxSize={6}
            color={infoColor}
            position="absolute"
            top={{ base: 'calc(50% - 12px)', lg: '10px', xl: 'calc(50% - 12px)' }}
            right="10px"
          />
        </LightMode>
      )}
    </Flex>
  );
};

export default chakra(StatsItem);
