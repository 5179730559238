import { Grid } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';

import txIcon from 'icons/transactions.svg';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import { fetchGET } from 'lib/fetchExchange';
import useIsMobile from 'lib/hooks/useIsMobile';

import StatsItem from './StatsItem';

const Stats3 = () => {
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_stats', {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const isMobile = useIsMobile();

  const [spot_volume_usd, setspot_volume_usd] = useState(0);
  const [spot_volume_usd_Zedxion, setspot_volume_usd_Zedxion] = useState(0);
  const [total_assets, settotal_assets] = useState(0);
  const fetchExchange = useCallback(async function () {
    try {

      const resultDataZedcex = await fetchGET("https://follow.ciaoaibot.com/openapi/robots_api/getZedcexExchangeData");
      setspot_volume_usd(resultDataZedcex.spot_volume_usd);
      settotal_assets(resultDataZedcex.total_assets);

      const resultDataZedxion = await fetchGET("https://follow.ciaoaibot.com/openapi/robots_api/getZedxionExchangeData");
      setspot_volume_usd_Zedxion(resultDataZedxion.spot_volume_usd)

    } catch (err) {
      console.error('fetchExchange', err);
    }
  }, []);

  useEffect(() => {
    fetchExchange();
  }, [fetchExchange]);

  if (isError) {
    return null;
  }

  let content;

  let itemsCount = 5;

  if (data) {

    content = (
      <>
        <StatsItem
          icon={txIcon}
          title="Total Supply"
          value={(Number(data.total_supply)).toLocaleString()}
          isLoading={isPlaceholderData}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="Price"
          value={`$ ` + (Number(data.coin_price)).toLocaleString()}
          isLoading={isPlaceholderData}
          leftBorder={true}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="Fully MarketCap"
          value={`$ ` + (Number(data.coin_price) * Number(data.total_supply)).toLocaleString()}
          isLoading={isPlaceholderData}
          leftBorder={!isMobile ? true : false}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="CS MarketCap"
          value={`$ ` + (Number(data.self_reported_market_cap)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={true}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="ZedCex (Vol 24h)"
          value={`$ ` + spot_volume_usd.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={!isMobile ? true : false}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="Financial Reserves (Total Assets)"
          value={`$ ` + total_assets.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={isMobile ? true : false}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="ZEDXION Exchange(Vol 24h)"
          value={`$ ` + spot_volume_usd_Zedxion.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={!isMobile ? true : false}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="totalmarket"
          value={`$ ` + (spot_volume_usd_Zedxion + spot_volume_usd).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={true}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="Total Value Locked TVL(Stake)"
          value={`$ ` + Number('3647256976').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={isMobile ? false : true}
          isTopLine={true}
        />
        <StatsItem
          icon={txIcon}
          title="Total Value Locked TVL(Bridge)"
          value={`$ ` + Number('25675235987').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          isLoading={isPlaceholderData}
          leftBorder={true}
          isTopLine={true}
        />
      </>
    );
  }

  return (
    <Grid
      gridTemplateColumns={{ lg: `repeat(${itemsCount}, 1fr)`, base: '1fr 1fr' }}
      gridTemplateRows={{ lg: 'none', base: undefined }}
      // gridGap="10px"
      paddingY="0px"
    >
      {content}
    </Grid>

  );
};

export default Stats3;
