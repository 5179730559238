import { chakra, Hide, Skeleton } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { useAppContext } from 'lib/contexts/app';
import * as cookies from 'lib/cookies';

import AdbutlerBanner from './AdbutlerBanner';
import CoinzillaBanner from './CoinzillaBanner';
import SliseBanner from './SliseBanner';

import { useRouter } from 'next/router';

const feature = config.features.adsBanner;

const AdBanner = ({ className, isLoading , type }: { className?: string; isLoading?: boolean; type?: string }) => {
  const hasAdblockCookie = cookies.get(cookies.NAMES.ADBLOCK_DETECTED, useAppContext().cookies);
  const router = useRouter();
  // console.log("router:  ** ", router);

  const getPathName = (() => {
    switch (router.route) {
      case '/tx/[hash]':
        return 'tx';
      case '/':
        return 'home';
      case "/address/[hash]":
        return "address";
      default:
        return '';
    }
  })

  if (!feature.isEnabled || hasAdblockCookie) {
    return null;
  }

  const content = (() => {
    switch (feature.provider) {
      case 'adbutler':
        return <AdbutlerBanner />;
      case 'coinzilla':
        return <CoinzillaBanner />;
      case 'slise':
        return <SliseBanner type={type ? type : getPathName()} />;
    }
  })();

  return (
    <Skeleton
      className={className}
      isLoaded={!isLoading}
      borderRadius="none"
      maxW={feature.provider === 'adbutler' ? feature.adButler.config.desktop.width : '728px'}
      w="100%"
      overflow="hidden"
      alignItems={"center"}
    >
      {content}
    </Skeleton>
  );
};

export default chakra(AdBanner);
