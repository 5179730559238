export type ZedcexExchangeData = {
  spot_volume_usd: number | 0;
  total_assets: number | 0;
}
export async function fetchGET(url: string): Promise<ZedcexExchangeData> {

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = await (response.json() as Promise<{ data: ZedcexExchangeData }>);
  return data.data;
}
