import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import Stats from 'ui/home/Stats';
import Stats2 from 'ui/home/Stats2';
import Stats3 from 'ui/home/Stats3';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import useIsMobile from 'lib/hooks/useIsMobile';

const Home = () => {

  const isMobile = useIsMobile();
  return (
    <>
      {/* <Box
        w="100%"
        // background={`${bg_image} no-repeat left center ${config.UI.homepage.plate.background}`}
        bgColor={"rgba(19,19,19,1)!important"}
        // bg={bg_image}
        // backgroundPosition={bg_image}
        // backgroundImage={ bg_image }
        borderRadius="24px"
        padding={{ base: '24px', lg: '48px' }}
        minW={{ base: 'unset', lg: '900px' }}
        data-label="hero plate"
      > */}
      <section
        style={
          {
            width: "100%",
            backgroundColor: "rgba(19,19,19,1)!important",
            backgroundImage: `url(/static/waves-light.svg)`,
            borderRadius: !isMobile ? "24px" : "0px",
            padding: isMobile ? '15px' : '48px',
          }
        }
      >
        <Flex mb={{ base: 6, lg: 2 }} justifyContent="space-between">
          <Heading
            as="h1"
            size={{ base: 'xs', lg: 'xl' }}
            lineHeight={{ base: '32px', lg: '50px' }}
            fontWeight={600}
            color={config.UI.homepage.plate.textColor}
          >
            {/* Welcome to {config.chain.name} explorer */}
            ZEDXION Smart Chain Explorer
          </Heading>
          <Box display={{ base: 'none', lg: 'block' }}>
            {config.features.account.isEnabled && <ProfileMenuDesktop />}
          </Box>
        </Flex>
        {/* <LightMode> */}
        <Flex height={{ base: '', lg: '50%' }} alignItems={'center'}>
          <SearchBar isHomepage />
          {!isMobile && <AdBanner display="flex" justifyContent="center" mt={{ base: '-30px', lg: '' }} width={"650px"} ml={20} type="dashboard_banner" />}
        </Flex>
        {/* </LightMode> */}
        <Box mt={{ base: '20px', lg: '0px' }} mb={{ base: '20px', lg: '' }}>
          <Text fontWeight={600} color={'gray.500'} fontSize={{ base: '11px', lg: 'md' }}>Sponsored:
            <span style={{ color: 'white' }}>  ZEDXION,</span>
            <span style={{ color: '#3784c3' }}> a pioneering NeoBanking Blockchain</span>
            {isMobile && <br />}
            {isMobile ?
              <span style={{ color: 'white' }}>boasts a staggering Billions Trading Volume (24h) and holds over Billions in Financial Reserves (Total Assets)</span> :
              <span style={{ color: 'white' }}>, boasts a staggering Billions Trading Volume (24h) and holds over Billions in Financial Reserves (Total Assets)</span>
            }
          </Text>
        </Box>
        {/* </Box> */}
      </section>
      <Box width={'100%'} paddingX={{ base: 4, lg: 0 }}>
        <Stats />
        <Stats2 />
        <Stats3 />
        <ChainIndicators />
        <AdBanner mt={{ base: 6, lg: 8 }} mx="auto" display="flex" justifyContent="center" />
        <Flex mt={8} direction={{ base: 'column', lg: 'row' }} columnGap={12} rowGap={8} >
          <LatestBlocks />
          <Box flexGrow={1}>
            <Transactions />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Home;
